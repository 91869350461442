/*
 * @version: 1.0
 * @Date: 2023-04-28 09:24:24
 * @LastEditors: 杨慢慢
 */
import request from '@/utils/request'

// 流程名称列表
export function processList(params) {
  return request({
    url: `/pc/flowable`,
    method: 'GET',
    params
  })
}

// 印章列表
export function getSealList(params) {
  return request({
    url: '/pc/seal/flow',
    method: 'GET',
    params
  })
}

//新增流程名称
export function addProcess(data) {
  return request({
    url: `/pc/flowable`,
    method: 'POST',
    data
  })
}

//修改流程名称
export function editProcess(data) {
  return request({
    url: `/pc/flowable`,
    method: 'PUT',
    data
  })
}

//删除流程名称
export function delProcess(params) {
  return request({
    url: `/pc/flowable`,
    method: 'DELETE',
    params
  })
}

// 查询流程名称
export function getProcessName(params) {
  return request({
    url: `/pc/flowable/checkName`,
    method: 'GET',
    params
  })
}

// 保存草稿
export function getDraft(params) {
  return request({
    url: `/pc/flowable/draft`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

// 修改状态
export function editType(params) {
  return request({
    url: '/pc/flowable/status',
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    params
  })
}

// 查询
export function queryProcess(params) {
  return request({
    url: '/pc/flowable/detail/' + params.processId,
    method: 'GET',
    // params
  })
}